import React, {Component} from "react";
import {Col, Row} from "antd";
import {CardItem} from "./CardItem";
import "./Card.css"

const style = {
    borderRadius:15,
    width:"100%",
    objectFit: "cover",
    objectPosition: "50% 50%"
}

const content = {
    backgroundColor:"#272591",
    borderRadius:15,
    marginTop:15
}

const styleH4 = {
    fontWeight:"bold",
    color: "white",
    textAlign: "center",
    alignContent: "center",
    margin:0,
    padding: "10px 0px 5px 0px"
}

const styleP = {
    color: "white",
    textAlign: "center",
    alignContent: "center",
    margin:0,
    paddingBottom:10
}

class Card extends Component {
    render() {
        return (
                <Row
                    gutter={
                    [
                        30,30
                    ]
                }>
                {CardItem.map((item, index) => {
                        return (
                                <Col xs={36} md={24} lg={12}>
                                    <img
                                        key={index}
                                        style={style}
                                        src={item.img}
                                        alt={item.alt}
                                        className="styleIMG"
                                    />
                                    <div style={content}>
                                        <h4 style={styleH4} className="styleH4" id="cardH4">{item.title}</h4>
                                        <p style={styleP} className="styleP" id="cardP">{item.description}</p>
                                    </div>
                                </Col>
                        )
                    }
                )
                }
                </Row>
        )
    }
}

export default Card