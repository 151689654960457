import React, {Component} from "react";
import {ReviewsItem} from "./PartnersCardItems";
import {Col, Row} from "antd";
import "./PartnerCard.css"

const style = {
    borderRadius:15,
    width: "100%"
}

const block = {
    backgroundColor:"white",
    width:"100%",
    height:"100%",
    borderRadius:15,
    display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    alignItems:"center"
}


class Card extends Component {

    render() {
        return (
            <Row
                gutter={
                    [
                        30,30
                    ]
                }>
                {ReviewsItem.map((item, index) => {
                        return (
                            <Col xs={24} md={12} lg={6}>
                                <a href={item.url} target="_blank">
                                <div style={block} className="partner">
                                    <img
                                        className={item.class}
                                        key={index}
                                        style={style}
                                        src={item.img}
                                        alt={item.alt}
                                    />
                                    <h3>{item.title}</h3>
                                </div>
                                </a>
                            </Col>
                        )
                    }
                )
                }
            </Row>
        )
    }
}

export default Card