import React, {Component} from "react";
import {CustomerItem} from "./CustomerItem";
import {Carousel,Row,Col} from 'antd';
import "./Customers.css"

const style = {
    borderRadius: 15,
    width: "100%",
    overflow: "hidden"
}

const styleH2 = {
    color: "#183323",
    textAlign:"center",
    marginTop:80,
    fontWeight:"bold",
    marginBottom:30
}

class Gallery extends Component {
    state = {
        media: null,
        nav: null
    };

    componentDidMount = () => {
        this.setState({
            media: this.media,
            nav: this.nav
        });
    };

    render() {
        return (
            <div>
                <h2 style={styleH2} className="styleH2">Customers</h2>
                <Row justify="space-around">
                    <Col flex="1140px">
                        <div>
                <Carousel
                    className="slider"
                    dots={false}
                    autoplay
                    slidesToScroll = {3}
                    slidesToShow={3}
                    draggable
                >   {CustomerItem.map((item, index) => {
                        return (
                            <a href={item.url} target="_blank">
                                        <img style={ style }
                                             key={index}
                                             src={item.img}
                                             alt={item.alt}
                                        />
                            </a>
                        )
                    }
                )
                }
                </Carousel>
                        </div>
                    </Col>
                </Row>
            </div>

        );
    }
}

export default Gallery