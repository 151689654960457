import ServerRoom from "../../../assets/mobileVersion/New/ServerRoom.png";
import DiplomS from "../../../assets/mobileVersion/New/DiplomS.png";
import { Parallax } from "rc-scroll-anim";
import EnergyImg from "../../../assets/mobileVersion/Energy/energy.jpeg";
import "./styles.css";

const New = () => {
  const main = {
    position: "relative",
    minHeight: "100vh",
  };
  const headerH2 = {
    fontWeight: "bold",
    color: "#183323",
    textAlign: "center",
    alignContent: "center",
    margin: "80px 0px 30px 0px",
  };
  const bg_image = {
    position: "absolute",
    zIndex: 0,
    opacity: 0.2,
    height: "100%",
    width: "100%",
    size: "cover",
    backgroundSize: "cover",
  };
  const container = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <>
      <h2 id="new" style={headerH2}>
        <strong style={{ fontSize: "50px" }}>ЭН</strong>овые
        <strong style={{ fontSize: "50px" }}>ТЭ</strong>
      </h2>
      <div style={main}>
        <img src={`${EnergyImg}`} alt="lemurs" style={bg_image} />
        <br />

        <Parallax
          animation={[
            { x: 0, opacity: 1, playScale: [0, 0.6] },
            { y: 0, playScale: [0, 0.7] },
            // { blur: screen ? "0px" : "30px", playScale: [0, 5] },
          ]}
          style={{
            transform: "translateX(0px)",
            filter: "blur(0px)",
            opacity: 0,
          }}
          className="code-box-shape"
        >
          <h5 align="center">
            Методология обеспечения требуемого уровня надежности трубопроводов с
            использованием роботизированных комплексов
          </h5>
          <br />
          <div style={container}>
            <h5 align="center">
              Работа удостоена Премии ПАО «Газпром» в области науки и техники
              2021 г.
            </h5>
          </div>

          <div className="diplom">
            <img alt="img" src={`${DiplomS}`} className="diplomImage"></img>
          </div>

          <div style={container}>
            <h5 style={{ padding: "20px" }} align="center">
              Система Оперативного Контроля технического состояния трубопроводов;<br/>
              ERP-системы оперативного управления и контроля
            </h5>
          </div>
          <div style={container}>
            <div
              style={{
                width: "100%",
                height: "300px",
                backgroundImage: `url(${ServerRoom})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
              }}
            ></div>
          </div>
          <br />
        </Parallax>
      </div>
    </>
  );
};

export default New;
