import Control from "../../../../assets/slider/Activity/Control.jpg";
import Diagnostics from "../../../../assets/slider/Activity/Diagnostics.jpg";
import Exam from "../../../../assets/slider/Activity/Exam.jpg";
import Expertise from "../../../../assets/slider/Activity/Expertise.jpeg";
import Innovation from "../../../../assets/slider/Activity/Innovation.jpg";

export const CarouselItem = [
  {
    parallax: "l",
    key: "a",
    title: "Expertise of industrial safety",
    Description:
      "The first conclusion was issued in 2006. An examination was carried out of more than 1,000 gas pipeline facilities and facilities of Gazprom transgaz Yugorsk LLC, Gazprom Kyrgyzstan LLC, Gazprom transgaz Krasnodar LLC, Gazprom transgaz Stavropol LLC, etc.",
    img: Expertise,
    alt: "Industrial safety expertise",
    classItem: "left",
  },
  {
    parallax: "r",
    key: "b",
    title: "Diagnosis of technical condition by non-destructive testing",
    Description:
      "Certification areas: boiler supervision facilities, gas supply systems, equipment for the oil and gas industry, equipment for explosive and chemically hazardous industries. Control methods used: radiation, ultrasonic, acoustic-emission, magnetic, eddy current, vibration diagnostics, electrical, visual and measuring",
    img: Control,
    alt: "Diagnosis of technical condition by non-destructive testing",
    classItem: "right",
  },
  {
    parallax: "l",
    key: "c",
    title: "In-line technical diagnostics",
    Description:
      "Starting from 2015, in-line diagnostics of more than 400 compressor stations, as well as more than 200 local facilities of the linear part with a total length of more than 1,000 kilometers, have been performed. OOO \"Gazprom transgaz Tomsk\", OOO \"Gazprom transgaz Volgograd\", etc.",
    img: Diagnostics,
    alt: "In-line technical diagnostics",
    classItem: "left",
  },
  {
    parallax: "r",
    key: "d",
    title: "In-line technical inspection",
    Description:
      "The tasks of pre-commissioning control of pipelines are: control of the cleanliness of the internal cavity of the pipeline, visual control of the ability of the ZRA, determination of the actual design of the pipeline, assessment of the parameters of the identified inconsistencies. The work was carried out at the facilities of OOO \"Gazprom transgaz Krasnodar\", South Strea Transport B.V., TurkStream, Nord Stream",
    img: Exam,
    alt: "In-line technical inspection",
    classItem: "right",
  },
  {
    parallax: "l",
    key: "e",
    title: "Innovative activity",
    Description:
      "LLC \"ENTE\" develops and implements new IT technologies: an automated information system for enterprise management, dispatching of the main processes of an enterprise, electronic document management, a software package for storage, as well as research on data on the technical condition of objects",
    img: Innovation,
    alt: "Innovative activity",
    classItem: "left",
  },
];
