import agp from "../../../../assets/slider/Customers/agp.png"
import gazKirg from "../../../../assets/slider/Customers/gaz-kirg.png"
import raspedelenie from "../../../../assets/slider/Customers/gazoraspredelenie.png"
import gt_chaik from "../../../../assets/slider/Customers/gt_chaik.png"
import gt_piter from "../../../../assets/slider/Customers/gt_piter.png"
import gt_surhat from "../../../../assets/slider/Customers/gt_surhut.png"
import gt_tomsk from "../../../../assets/slider/Customers/gt_tomsk.png"
import gtKazan from "../../../../assets/slider/Customers/gt-kazan.png"
import gtKrasnodar from "../../../../assets/slider/Customers/gt-krasnodar.png"
import gtStavropol from "../../../../assets/slider/Customers/gt-stavropol.png"
import gtUhta from "../../../../assets/slider/Customers/gt-uhta.png"
import gtVolgograd from "../../../../assets/slider/Customers/gt-volgograd.png"
import gtYugorsk from "../../../../assets/slider/Customers/gt-yugorsk.png"
import halliburton from "../../../../assets/slider/Customers/hallibuton.png"
import lukoil from "../../../../assets/slider/Customers/lukioil.png"
import stream from "../../../../assets/slider/Customers/stream.png"
import trans from "../../../../assets/slider/Customers/trans.png"
import ttg from "../../../../assets/slider/Customers/ttg.png"

export const CustomerItem = [
    {
        img: gt_chaik,
        alt: "Gazprom Tchaikovsky",
        url: "https://tchaikovsky-tr.gazprom.ru/"
    },
    {
        img: halliburton,
        alt: "HALLIBURTON",
        url: "https://www.halliburton.com/"
    },
    {
        img: gazKirg,
        alt: "Gazprom Kyrgyzstan",
        url: "https://kyrgyzstan.gazprom.ru/"
    },
    {
        img: raspedelenie,
        alt: "Gazprom Gas Distribution",
        url: "https://gazoraspredelenie.gazprom.ru/"
    },
    {
        img:ttg,
        alt: "TiraspolTransgaz",
        url: "https://www.ttgpmr.com/"
    },
    {
        img: gt_piter,
        alt: "Gazprom St. Petersburg",
        url: "https://spb-tr.gazprom.ru/"
    },
    {
        img: gt_surhat,
        alt: "Gazprom Surgut",
        url: "https://surgut-tr.gazprom.ru/"
    },
    {
        img:stream,
        alt: "South Stream",
        url: "https://www.south-stream-transport.com/"
    },
    {
        img: gt_tomsk,
        alt: "Gazprom Tomsk",
        url: "https://tomsk-tr.gazprom.ru/"
    },
    {
        img: gtKazan,
        alt: "Gazprom Kazan",
        url: "https://kazan-tr.gazprom.ru/"
    },
    {
        img: lukoil,
        alt: "LUKOIL",
        url: "https://lukoil.ru/"
    },
    {
        img: gtKrasnodar,
        alt: "Gazprom Krasnodar",
        url: "https://krasnodar-tr.gazprom.ru/"
    },
    {
        img: gtStavropol,
        alt: "Gazprom Stavropol",
        url: "https://stavropol-tr.gazprom.ru/"
    },
    {
        img:agp,
        alt: "AGP",
        url: "http://www.agp.com.kz/"
    },
    {
        img: gtUhta,
        alt: "Gazprom Ukhta",
        url: "https://ukhta-tr.gazprom.ru/"
    },
    {
        img: gtVolgograd,
        alt: "Gazprom Volgograd",
        url: "https://volgograd-tr.gazprom.ru/"
    },
    {
        img:trans,
        alt: "Transneft",
        url: "https://www.transneft.ru/"
    },
    {
        img: gtYugorsk,
        alt: "Gazprom Yugorsk",
        url: "https://yugorsk-tr.gazprom.ru/"
    }
]