import React from "react";
import { Card } from 'antd';
import {blockKeyInfoItems} from "./BlockKeyInfoItems";
import {Row,Col} from "antd";

const { Meta } = Card;

const BlockKeyInfo = () => {
    return (
        <Row gutter={
            [
                15,15
            ]
        }>
        {blockKeyInfoItems.map((item, index) => {
            return (
                <Col xs={24} md={12} lg={6}>
             <Card key={index}
                >
                 <div style={ {width:"100%", textAlign:"center"} }>
                 {item.icon}
                 <br/>
                     <br/>
                 </div>
                    <Meta title={item.title} description={item.description} style={ {textAlign: "center"} }/>
                </Card>
            </Col>
            )
            }
            )
        }
        </Row>
    )
}
export default BlockKeyInfo
