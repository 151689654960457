import BlockKeyInfo from "./blockKeyInfo/BlockKeyInfo";
import { Parallax } from "rc-scroll-anim";
import EnergyImg from "../../../../assets/mobileVersion/Energy/energy.jpeg";
import Rainbw from "../../../../assets/mobileVersion/Ecology/Rainbw.jpeg";

const Ecology = () => {
  const main = {
    minHeight: "80vh",
    margin: "80px 0px 0px 0px",
    position: "relative",
  };
  const bg_image = {
    position: "absolute",
    zIndex: 0,
    opacity: 0.2,
    height: "100%",
    width: "100%",
  };
  const headerH2 = {
    fontWeight: "bold",
    // color: "white",
    textAlign: "center",
    alignContent: "center",
    margin: "50px 0px 20px 0px",
  };
  return (
    <>
      <h2 id="ecology" style={headerH2}>
        <strong style={{ fontSize: "50px" }}>ENTE</strong>cology
      </h2>
      <div style={main}>
        <img src={`${EnergyImg}`} alt="lemurs" style={bg_image} />
        <img
          src={`${Rainbw}`}
          alt="rainbow"
          style={{ position: "relative", zIndex: 1, width: "100%" }}
        />
        <Parallax
          animation={[
            { x: 0, opacity: 1, playScale: [0, 0.6] },
            { y: 0, playScale: [0, 0.5] },
            // { blur: screen ? "0px" : "30px", playScale: [0, 1] },
          ]}
          style={{
            transform: "translateX(-100px)",
            filter: "blur(0px)",
            opacity: 0,
          }}
          className="code-box-shape"
        >
          <BlockKeyInfo />
        </Parallax>
      </div>
    </>
  );
};

export default Ecology;
