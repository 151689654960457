import BlockKeyInfo from "./blockKeyInfo/BlockKeyInfo";
import { Parallax } from "rc-scroll-anim";
import EnergyImg from "../../../../assets/mobileVersion/Energy/Engy.jpeg";
import Customers from "./Customers/Customers";
import "./styles.css";

const Energy = () => {
  const main = {
    position: "relative",
  };
  const bg_image = {
    position: "absolute",
    zIndex: 0,
    opacity: 0.2,
    height: "100%",
    width: "100%",
    size: "cover",
    backgroundSize: "cover",
  };
  const headerH2 = {
    fontWeight: "bold",
    color: "#183323",
    textAlign: "center",
    alignContent: "center",
    margin: "20px 0px 30px 0px",
    zIndex: 2,
  };
  return (
    <>
      <h2 id="energy" style={headerH2}>
        <strong style={{ fontSize: "50px" }}>E</strong>nergy
        <strong style={{ fontSize: "50px" }}>NTE</strong>
      </h2>
      <div style={main} id="wo">
        <img src={`${EnergyImg}`} alt="lemurs" style={bg_image} />
        <Parallax
          animation={[
            { x: 0, opacity: 1, playScale: [0, 0.6] },
            { y: 0, playScale: [0, 0.7] },
          ]}
          style={{
            transform: "translateX(-100px)",
            filter: "blur(0px)",
            opacity: 0,
          }}
          className="code-box-shape"
        >
          <BlockKeyInfo />
        </Parallax>
        <Customers />
        {/* <br /> */}

        <h2 style={headerH2}>Mass media about us</h2>
        <br />
        <h5 align="center" style={{ position: "relative" }}>
          <a href="https://docs.google.com/viewer?url=https://storapi.ente-ltd.ru/cdn/videos/Article_2015_.pdf">
            Intrauterine control of process pipelines of Gazprom's compressor
            stations using robotic scanners. Gas industry.
          </a>
        </h5>
        <br />
        <h5 align="center" style={{ position: "relative" }}>
          <a href="https://glavportal.com/materials/partnerstvo-novatorov-effektivnost-v-prioritete/">
            Partnership of innovators - efficiency is a priority. Russian
            business
          </a>
        </h5>
        <br />
        <h5 align="center" style={{ position: "relative" }}>
          <a href="https://tass.ru/ekonomika/14088173">
            The Perm company will create for Gazprom diagnostic robots for
            hard-to-reach gas pipelines. TASS
          </a>
        </h5>
        <br />
        <iframe
          style={{ position: "relative" }}
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/NtoxsPuT6Hc"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </>
  );
};

export default Energy;
