import EnNavbar from "./Header/Navbar/Navbar";
import EnCarouselBox from "./Header/Slider/CarouselBox";
import EnAboutUs from "./Body/AboutUs/AboutUs";
import EnSliderActivity from "./Body/SliderActivity/SliderActivity";
import EnTechEquipment from "./Body/TechEquipment/TechEquipment";
import Presentation from "./Body/Presentation";
import EnDocumentation from "./Body/Documentation/Documentation";
import EnReviews from "./Body/Reviews/Reviews";
import EnPartners from "./Body/Partners/Partners";
import EnCustomers from "./Body/Customers/Customers";
// import EnFluCarousel from "./FluCarousel";
import EnFooter from "./Footer/Footer";

const EnVersion = () => {
  return (
    <>
      <EnNavbar />
      <EnCarouselBox />
      <EnAboutUs />
      <EnSliderActivity />
      <EnTechEquipment />
      <Presentation />
      <EnDocumentation />
      <EnReviews />
      <EnPartners />
      <EnCustomers />
      {/* <EnFluCarousel /> */}
      <EnFooter />
    </>
  );
};

export default EnVersion;