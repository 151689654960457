import Banner from "./Banner";
import Energy from "./Energy";
import New from "./New";
import Technology from "./Technology";
import Presentation from "./Presentation";
import Ecology from "./Ecology";
import NewFooter from "./Footer/Footer";
import NewNavbar from "./Header/Navbar/Navbar";

const MobileVersion = () => {
  return (
    <>
      <NewNavbar />
      <Banner />
      <Energy />
      <New />
      <Technology />
      <Presentation />
      <Ecology />
      <NewFooter />
    </>
  );
};

export default MobileVersion;
