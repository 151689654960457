import {Typography} from "antd";

export const AboutUsItem = [
    {
        title: "About us",
    content:
    <Typography.Text>
        The team of LLC "ENTE" consists of more than 150 highly qualified specialists (with an average work experience in the field of 10 years), forming a cohesive team to solve tasks of any complexity. The company has its own production and technical base, modern non-destructive testing complexes, mobile specialized diagnostic laboratories, and innovative technologies in various areas of activity.<br/><br/>
        The basic principles of the company's existence are the highest quality of work performed, the constant improvement of methods and the search for innovative solutions to the tasks set, the maximum involvement of the team and the disclosure of the creative potential of employees.<br/><br/>
        LLC "ENTE" actively cooperates and has partnership relations with Ufa State Petroleum Technical University, Saint Petersburg State University, Izhevsk State Technical University, Institute of Applied Mechanics of the Ural Branch of the Russian Academy of Sciences, National Research University "Moscow Power Engineering Institute", LLC "INT "GeoSpectr", LLC "AKS" and others.<br/><br/>
        The main customers are GAZPROM PJSC, Gazprom Gas Distribution PJSC, LUKOIL PJSC, Transneft JSC, etc. The geography of work performed by ENTE LLC extends from Yamal to the Krasnodar Territory, and also includes foreign regions. <br/><br/>
        The quality of work of ENTE LLC is confirmed by positive feedback from Orgenergogaz OJSC, Gazprom Gas Distribution OJSC, Gazprom Transgaz Tchaikovsky LLC, Gazprom Transgaz Krasnodar LLC, Gazprom Transgaz Stavropol LLC, Gazprom Transgaz Moscow LLC Gazprom Transgaz Kazan, OOO Gazprom Transgaz Surgut, OOO Gazprom Transgaz Yugorsk, OOO Gazprom UGS, Administration of the Perm Territory, OOO Neftegazdetal, etc.<br/><br/>
        ENTE LLC is a socially responsible company that actively promotes educational projects and supports children's sports.<br/><br/>
        With the participation of ENTE LLC, the companies IntroScan Technology JSC, Elixir-D LLC were formed.
    </Typography.Text>
    }

    ]
