import React, {Component} from "react";
import "./Button.css"

export default class CarouselBox extends Component {
    render() {
        return (
            <div className="container">
                <div className="btn" style={{ borderRadius: 15, backgroundColor: "#272590",color:"white", fontWeight:"bold"}}>
                    <span>Learn more</span>
                    <div className="dot"/>
                </div>
            </div>
        )
    }
}
