import "./BlockKeyInfoItems.css"
import Employees from "../../../../assets/mobileVersion/Energy/Employees.svg"
import Foundation from "../../../../assets/mobileVersion/Energy/Foundation.svg"
import Customers from "../../../../assets/mobileVersion/Energy/Customers.svg"
import Introscan from "../../../../assets/mobileVersion/Energy/Introscan.svg"
import World from "../../../../assets/mobileVersion/Energy/World.svg"

const styleTitle = {
    color: "#183323",
    fontWeight:"bold",
    textTransform: "uppercase",
    margin: 0,
    fontSize: '20px'
}

const styleDescription = {
    color: "#183323",
    margin:0,
    fontSize: '20px'
}

export const blockKeyInfoItems = [
    {
        title: <h4 style={ styleTitle } className="styleH4">150+ сотрудников</h4>,
        icon: Employees,
        description: <p style={ styleDescription } className="description">Коллектив ООО «ЭНТЭ» - сплоченная Команда высококвалифицированных специалистов из различных отраслей промышленности </p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">20+ лет работы</h4>,
        icon: Foundation,
        description: <p style={ styleDescription } className="description">Дата основания<br/>1 августа 2000 года</p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">2 филиала</h4>,
        icon: World,
        description: <p style={ styleDescription } className="description">ЭНТЭ-ИЖ (г. Ижевск),<br/>
        ЭНТЭ-ЮГРА (г. Югорск)        
        </p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">Партнеры</h4>,
        icon: Introscan,
        description: <p style={ styleDescription } className="description">АО ИнтроСкан Технолоджи,
        ООО «АКС»,<br/>
        АО «Системы управления,<br/>
        ИЖГТУ им. М.Т. Калашникова,<br/>
        ПАО «Газпром»
        </p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">30+ заказчиков</h4>,
        icon: Customers,
        description: <p style={ styleDescription } className="description">Российские и<br/>зарубежные компании</p>,
    },
]