import React, {Component} from "react";
import {Row,Col} from "antd";
import Card from "./Card";

const contentStyleH2 = {
    fontWeight:"bold",
    color: "#183323",
    textAlign: "center",
    alignContent: "center",
    margin:"50px 0px 30px 0px"
}

class TechEquipment extends Component {

    render() {
        return (

            <div className="TechEquipment">
                <h2 style={contentStyleH2} className="styleH2">Техническое оснащение</h2>
                <Row justify="space-around"><Col flex="1140px">
                    <Card/>
                </Col></Row>
            </div>
        )
    }
}

export default TechEquipment
