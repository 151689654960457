import "./BlockKeyInfoItems.css"
import Robot from "../../../../../assets/mobileVersion/Technology/Robot_2.jpeg"
import Emplyee from "../../../../../assets/mobileVersion/Technology/Employee_3.jpeg"
import Chemodan from "../../../../../assets/mobileVersion/Technology/Chemodan_2.jpeg"

const styleDescription = {
    color: "#183323",
    fontSize: '18px',
    margin:0
}

export const blockKeyInfoItems = [
    {
        // title: <h4 style={ styleTitle } className="styleH4">120+ сотрудников</h4>,
        icon: Robot,
        description: <p style={ styleDescription } className="description">In-line technical diagnostics and inspection of pipelines</p>,
    },
    {
        // title: <h4 style={ styleTitle } className="styleH4">20+ лет работы</h4>,
        icon: Emplyee,
        description: <p style={ styleDescription } className="description">Specialized mobile auto laboratories</p>,
    },
    {
        // title: <h4 style={ styleTitle } className="styleH4">30+ заказчиков</h4>,
        icon: Chemodan,
        description: <p style={ styleDescription } className="description">Diagnostics of the technical condition by non-destructive testing
        </p>,
    },
]