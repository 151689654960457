import {
TeamOutlined,
GlobalOutlined,
FundProjectionScreenOutlined,
LikeOutlined
} from '@ant-design/icons';
import "./BlockKeyInfoItems.css"

const style = {
    fontSize:100,
    borderRadius:16,
    color: "white",
    backgroundColor: "#389e0d",
    padding:"10px"
}

const styleTitle = {
    color: "#183323",
    fontWeight:"bold",
    textTransform: "uppercase",
    margin: 0
}

const styleDescription = {
    color: "#183323",
    margin:0
}

export const blockKeyInfoItems = [
    {
        title: <h4 style={ styleTitle } className="styleH4">20+ years of work</h4>,
        icon: <FundProjectionScreenOutlined style={style}/>,
        description: <p style={ styleDescription } className="description">Founded <br/> 1 August 2000</p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">150+ employees</h4>,
        icon: <TeamOutlined style={style}/>,
        description: <p style={ styleDescription } className="description">Small innovative enterprise</p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">30+ customers</h4>,
        icon: <LikeOutlined style={style}/>,
        description: <p style={ styleDescription } className="description">Including 19 subsidiaries of PJSC Gazprom</p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">40+ regions</h4>,
        icon: <GlobalOutlined style={style}/>,
        description: <p style={ styleDescription } className="description">Russia and near <br/> abroad</p>,
    },
]