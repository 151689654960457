import Control from "../../../assets/slider/Activity/Control.jpg";
import Diagnostics from "../../../assets/slider/Activity/Diagnostics.jpg";
import Exam from "../../../assets/slider/Activity/Exam.jpg";
import Expertise from "../../../assets/slider/Activity/Expertise.jpeg";
import Innovation from "../../../assets/slider/Activity/Innovation.jpg";

export const CarouselItem = [
  {
    parallax: "l",
    key: "a",
    title: "Экспертиза промышленной безопасности",
    Description:
      "Первое заключение выдано в 2006 году. Проведена экспертиза более 1000 объектов магистральных газопроводов и объектов ООО «Газпром трансгаз Югорск», ОсОО «Газпром Кыргызстан», ООО «Газпром трансгаз Краснодар», ООО «Газпром трансгаз Ставрополь»  и др.",
    img: Expertise,
    alt: "Экспертиза промышленной безопасности",
    classItem: "left",
  },
  {
    parallax: "r",
    key: "b",
    title: "Диагностика технического состояния методом неразрушающего контроля",
    Description:
      "Области аттестации: объекты котлонадзора, системы газоснабжения, оборудование нефтяной и газовой промышленности, оборудование взрывопожароопасных и химически опасных производств. Используемые методы контроля: радиационный, ультразвуковой, акустико-эмиссионный, магнитный, вихретоковый, вибродиагностический, электрический, визуальный и измерительный",
    img: Control,
    alt: "Диагностика технического состояния методом неразрушающего контроля",
    classItem: "right",
  },
  {
    parallax: "l",
    key: "c",
    title: "Внутритрубное техническое диагностирование",
    Description:
      "Начиная с 2015 года выполнена внутритрубная диагностика более 400 компрессорных станций, а также более 200 локальных объектов линейной части общей протяженностью более 1000 километров. Работы выполнялись на объектах ООО «Газпром трансгаз Югорск», ООО «Газпром трансгаз Чайковский», ООО «Тираспольтрансгаз-Приднестровье», ООО «Газпром трансгаз Томск», ООО «Газпром трансгаз Волгоград» и др.",
    img: Diagnostics,
    alt: "Внутритрубное техническое диагностирование",
    classItem: "left",
  },
  {
    parallax: "r",
    key: "d",
    title: "Внутритрубное техническое обследование",
    Description:
      "Задачами предпускного контроля трубопроводов являются: контроль чистоты внутренней полости трубопровода, визуальный контроль способности ЗРА, определение фактической конструкции трубопровода, оценка параметров выявленных несоответствий. Работы выполнялись на объектах ООО «Газпром трансгаз Краснодар», South Strea Transport B.V., TurkStream, Северный поток",
    img: Exam,
    alt: "Внутритрубное техническое обследование",
    classItem: "right",
  },
  {
    parallax: "l",
    key: "e",
    title: "Инновационная деятельность",
    Description:
      "ООО «ЭНТЭ» разрабатывает, развивает и внедряет новые IT-технологии: автоматизированная информационная система управления предприятием, диспетчеризация основных процессов предприятия, электронный документооборот, программный комплекс хранения, а также исследования данных о техническом состоянии объектов",
    img: Innovation,
    alt: "Инновационная деятельность",
    classItem: "left",
  },
];
