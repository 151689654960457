import React, { Component } from "react";
// import logo from "../../../../assets/logo-dark.svg";
import logo from "../../../../assets/logo.svg";
import { MenuItems } from "./MenuItems";
import "./Navbar.css";
import { Row, Col } from "antd";
import { Space } from "antd";
import ButtonLang from "../../Buttons/Language/ButtonLang";

class Navbar extends Component {
  state = { clicked: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  render() {
    return (
      <Row justify="space-around">
        <Col flex="1140px">
          <nav className="NavbarItems" id="home">
            <div className="menu-icon" onClick={this.handleClick}>
              <i
                className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
              />
            </div>
            <div>
              <Space>
                <div className="navbar-logo">
                  <img src={logo} alt="Logotype" />
                  <p>
                    <strong>LLC "ENTE"</strong>
                    <br />
                    Creating a secure future
                  </p>
                </div>
                <div style={{ paddingLeft: 119 }}>
                  <div
                    className={
                      this.state.clicked ? "nav-menu active" : "nav-menu"
                    }
                  >
                    {MenuItems.map((item, index) => {
                      return (
                        <a
                          className={item.cName}
                          href={item.url}
                          style={{ padding: "5px 15px" }}
                          id="nav-link"
                          onClick={this.handleClick}
                        >
                          {item.title}
                        </a>
                      );
                    })}
                  </div>
                </div>
                <div className="ButtonLanguage">
                  <ButtonLang />
                </div>
              </Space>
            </div>
          </nav>
        </Col>
      </Row>
    );
  }
}

export default Navbar;
