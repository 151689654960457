import Energy from "../../../../assets/slider/HomeSlide/Energy.jpg"
import NewTechnologies from "../../../../assets/slider/HomeSlide/NewTechnologies.jpg"
import Ecology from "../../../../assets/slider/HomeSlide/Ecology.jpeg"

export const CarouselItem = [
    {
        title:"Energy, New Technologies, Ecology",
        Description: "These are the keywords that define the ideology of creating a company",
        button:"Learn more",
        img:Energy,
        alt:"Energy"
    },
    {
        title:"Energy, New Technologies, Ecology",
        Description: "These are the keywords that define the ideology of creating a company",
        img:NewTechnologies,
        alt:"New technologies"
    },
    {
        title:"Energy, New Technologies, Ecology",
        Description: "These are the keywords that define the ideology of creating a company",
        img:Ecology,
        alt:"Ecology"
    }
]