import SpecLabs from "../../../../assets/TechEquipment/SpecLab.jpeg"
import Equip from "../../../../assets/TechEquipment/Equip.jpg"
import Car from "../../../../assets/TechEquipment/car.jpg"
import Introscan from "../../../../assets/TechEquipment/Introscan.jpg"


export const CardItem = [
    {
        img: SpecLabs,
        alt: "Mobile technologies",
        title: "Dedicated Mobile Technologies",
        description: "Accommodation of field teams for in-line diagnostics in modern laboratories",
    },    {
        img: Equip,
        alt: "Modern high-precision equipment",
        title: "Modern high-precision equipment",
        description: "Equipment of field teams for technical diagnostics with modern non-destructive testing devices"
    },    {
        img: Car,
        alt: "cars",
        title: "Off-Road Vehicles",
        description: "Equipment of field teams for technical diagnostics with all-wheel drive vehicles"
    },    {
        img: Introscan,
        alt: "Introscan",
        title: "Diagnostic complexes A2072 \"IntroScan\"",
        description: "Use for non-destructive testing of metal and welded joints of pipeline parts"
    }
]