import context from "./context/context";
import "antd/dist/antd.css";
import SliderActivity from "../src/components/Body/SliderActivity/SliderActivity";
import "./App.css";
import AboutUs from "./components/Body/AboutUs/AboutUs";
import Customers from "./components/Body/Customers/Customers";
import Documentation from "./components/Body/Documentation/Documentation";
import Partners from "./components/Body/Partners/Partners";
import Reviews from "./components/Body/Reviews/Reviews";
import TechEquipment from "./components/Body/TechEquipment/TechEquipment";
import Presentation from "./components/Body/Presentation";
// import FluCarousel from "./components/FluCarousel";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Header/Navbar/Navbar";
import CarouselBox from "./components/Header/Slider/CarouselBox";

import MobileVersion from "./components/mobileVersion";
import EnMobileVersion from "./components/En/mobileVersion";
import EnVersion from "./components/En/index";

import { isMobile } from 'react-device-detect';

import { useState } from "react";

function App() {
  const [language, setLanguage] = useState(navigator.language.split("-")[0]);
  const switchLanguage = (language) => {
    setLanguage(language);
  };

  return (
    <context.Provider value={{ switchLanguage, language }}>
      <div className="App">
        {language === "ru" && !isMobile ? (
          <>
            <Navbar />
            <CarouselBox />
            <AboutUs />
            <SliderActivity />
            <TechEquipment />
            <Presentation/>
            <Documentation />
            <Reviews />
            <Partners />
            <Customers />
            {/* <FluCarousel /> */}
            <Footer />
          </>
        ) : language === "ru" && isMobile ? (
          <MobileVersion />
        ) : language === "en" && isMobile ? (
          <EnMobileVersion />
        ) : (
          <EnVersion />
        )}
      </div>
    </context.Provider>
  );
}

export default App;
