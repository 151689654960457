export const MenuItems = [
    {
        title: "Energy",
        url: '#energy',
        cName: 'nav-links'
    },
    {
        title: "New",
        url: '#new',
        cName: 'nav-links'
    },
    {
        title: "Technologies",
        url: '#technology',
        cName: 'nav-links'
    },
    {
        title: "Ecology",
        url: '#ecology',
        cName: 'nav-links'
    },

]