import Energy from "../../../assets/slider/HomeSlide/Energy.jpg"
import NewTechnologies from "../../../assets/slider/HomeSlide/NewTechnologies.jpg"
import Ecology from "../../../assets/slider/HomeSlide/Ecology.jpeg"

export const CarouselItem = [
    {
        title:"Энергия, Новые технологии, Экология",
        Description: "Это ключевые слова, определяющие идеологию создания компании",
        button:"Узнать больше",
        img:Energy,
        alt:"Энергия"
    },
    {
        title:"Энергия, Новые технологии, Экология",
        Description: "Это ключевые слова, определяющие идеологию создания компании",
        img:NewTechnologies,
        alt:"Новые технологии"
    },
    {
        title:"Энергия, Новые технологии, Экология",
        Description: "Это ключевые слова, определяющие идеологию создания компании",
        img:Ecology,
        alt:"Экология"
    }
]