import SpecLabs from "../../../assets/TechEquipment/SpecLab.jpeg"
import Equip from "../../../assets/TechEquipment/Equip.jpg"
import Car from "../../../assets/TechEquipment/car.jpg"
import Introscan from "../../../assets/TechEquipment/Introscan.jpg"


export const CardItem = [
    {
        img: SpecLabs,
        alt: "Передвижные технологии",
        title: "Специализированные передвижные технологии",
        description: "Размещение полевых групп по внутритрубной диагностике в современнных лабораториях",
    },    {
        img: Equip,
        alt: "Современное высокоточное оборудование",
        title: "Современное высокоточное оборудование",
        description: "Оснащение полевых бригад по техническому диагностированию современными приборами неразрушаюего контроля"
    },    {
        img: Car,
        alt: "Автомобили",
        title: "Автомобили повышенной проходимости",
        description: "Оснащение полевых бригад по техническому диагностированию полноприводными автомобилями"
    },    {
        img: Introscan,
        alt: "Introscan",
        title: "Диагностические комплексы А2072 «IntroScan»",
        description: "Использование для неразрушающего контроля металла и сварных соединений деталей трубопроводов"
    }
]