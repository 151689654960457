import BlockKeyInfo from "./blockKeyInfo/BlockKeyInfo";
import { Parallax } from "rc-scroll-anim";
import EnergyImg from "../../../assets/mobileVersion/Energy/energy.jpeg";

const Technology = () => {
  const main = {
    minHeight: "100vh",
    position: "relative",
  };
  const bg_image = {
    position: "absolute",
    zIndex: 0,
    opacity: 0.2,
    height: "100%",
    width: "100%",
    size: "cover",
    backgroundSize: "cover",
  };
  const container = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 0px 0px 0px",
    // backgroundColor: 'gray'
  };
  const headerH2 = {
    fontWeight: "bold",
    color: "#183323",
    textAlign: "center",
    alignContent: "center",
    margin: "80px 0px 30px 0px",
  };
  return (
    <>
      <h2 id="technology" style={headerH2}>
        <strong style={{ fontSize: "50px" }}>ЭНТ</strong>ехнологии
        <strong style={{ fontSize: "50px" }}>Э</strong>
      </h2>
      <div style={main}>
        <img src={`${EnergyImg}`} alt="lemurs" style={bg_image} />
        <div style={container}>
          <Parallax
            animation={[
              { x: 0, opacity: 1, playScale: [0, 0.6] },
              { y: 0, playScale: [0, 0.7] },
              // { blur: screen ? "0px" : "30px", playScale: [0, 5] },
            ]}
            style={{
              transform: "translateX(0px)",
              filter: "blur(0px)",
              opacity: 0,
            }}
            className="code-box-shape"
          >
            <BlockKeyInfo />
          </Parallax>
        </div>
        <br />
        <iframe
          style={{ position: "relative" }}
          width="100%"
          height="315"
          src="https://storapi.ente-ltd.ru/cdn/videos/%D0%902072.compressed.mp4"
          title="ENTE player"
          frameborder="0"
          allow=" autoplay;  accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen="allowfullscreen"
        ></iframe>
      </div>
    </>
  );
};

export default Technology;
