import agp from "../../../assets/slider/Customers/agp.png"
import gazKirg from "../../../assets/slider/Customers/gaz-kirg.png"
import raspedelenie from "../../../assets/slider/Customers/gazoraspredelenie.png"
import gt_chaik from "../../../assets/slider/Customers/gt_chaik.png"
import gt_piter from "../../../assets/slider/Customers/gt_piter.png"
import gt_surhat from "../../../assets/slider/Customers/gt_surhut.png"
import gt_tomsk from "../../../assets/slider/Customers/gt_tomsk.png"
import gtKazan from "../../../assets/slider/Customers/gt-kazan.png"
import gtKrasnodar from "../../../assets/slider/Customers/gt-krasnodar.png"
import gtStavropol from "../../../assets/slider/Customers/gt-stavropol.png"
import gtUhta from "../../../assets/slider/Customers/gt-uhta.png"
import gtVolgograd from "../../../assets/slider/Customers/gt-volgograd.png"
import gtYugorsk from "../../../assets/slider/Customers/gt-yugorsk.png"
import halliburton from "../../../assets/slider/Customers/hallibuton.png"
import lukoil from "../../../assets/slider/Customers/lukioil.png"
import stream from "../../../assets/slider/Customers/stream.png"
import trans from "../../../assets/slider/Customers/trans.png"
import ttg from "../../../assets/slider/Customers/ttg.png"

export const CustomerItem = [
    {
        img: gt_chaik,
        alt: "Газпром Чайковский",
        url: "https://tchaikovsky-tr.gazprom.ru/"
    },
    {
        img: halliburton,
        alt: "HALLIBURTON",
        url: "https://www.halliburton.com/"
    },
    {
        img: gazKirg,
        alt: "Газпром Кыргызстан",
        url: "https://kyrgyzstan.gazprom.ru/"
    },
    {
        img: raspedelenie,
        alt: "Газпром Газораспределение",
        url: "https://gazoraspredelenie.gazprom.ru/"
    },
    {
        img: ttg,
        alt: "ТираспольТрансгаз",
        url: "https://www.ttgpmr.com/"
    },
    {
        img: gt_piter,
        alt: "Газпром Санкт-Петербург",
        url: "https://spb-tr.gazprom.ru/"
    },
    {
        img: gt_surhat,
        alt: "Газпром Сургут",
        url: "https://surgut-tr.gazprom.ru/"
    },
    {
        img: stream,
        alt: "South Stream",
        url: "https://www.south-stream-transport.com/"
    },
    {
        img: gt_tomsk,
        alt: "Газпром Томск",
        url: "https://tomsk-tr.gazprom.ru/"
    },
    {
        img: gtKazan,
        alt: "Газпром Казань",
        url: "https://kazan-tr.gazprom.ru/"
    },
    {
        img: lukoil,
        alt: "ЛУКОЙЛ",
        url: "https://lukoil.ru/"
    },
    {
        img: gtKrasnodar,
        alt: "Газпром Краснодар",
        url: "https://krasnodar-tr.gazprom.ru/"
    },
    {
        img: gtStavropol,
        alt: "Газпром Ставрополь",
        url: "https://stavropol-tr.gazprom.ru/"
    },
    {
        img: agp,
        alt: "AGP",
        url: "http://www.agp.com.kz/"
    },
    {
        img: gtUhta,
        alt: "Газпром Ухта",
        url: "https://ukhta-tr.gazprom.ru/"
    },
    {
        img: gtVolgograd,
        alt: "Газпром Волгоград",
        url: "https://volgograd-tr.gazprom.ru/"
    },
    {
        img: trans,
        alt: "Транснефть",
        url: "https://www.transneft.ru/"
    },
    {
        img: gtYugorsk,
        alt: "Газпром Югорск",
        url: "https://yugorsk-tr.gazprom.ru/"
    }
]