import React,{Component} from "react";
import {CarouselItem} from "./SliderActivityItems";
import {Row,Col} from "antd";
import "./SliderActivity.css"
import { Parallax } from 'rc-scroll-anim';

const style = {
    borderRadius: 15,
    width: "100%",
    overflow: "hidden",
    objectFit: "cover",
    objectPosition: "50% 50%",
    height:"550px",
    marginBottom: 30
}

const styleH2 = {
 color: "#183323",
    textAlign:"center",
    marginTop:56,
    fontWeight:"bold",
    marginBottom:30,
}

const styleH5 = {
    color: "#183323",
    textAlign: "center",
    fontWeight: "bold",
    padding: "25px 0px 5px 0px",
    margin:"0 0 15px 0px",
    textTransform:"uppercase"
}

const styleP = {
    marginTop: 15,
    color: "#183323",
    textAlign: "center"
}

class Gallery extends Component {
    state = {
        media: null,
        nav: null
    };


    componentDidMount = () => {
        this.setState({
            media: this.media,
            nav: this.nav
        });
    };

    render() {
        return (
            <div id="activity">
                {/*<Carousel*/}
                {/*    fade*/}
                {/*    autoplay*/}
                {/*    draggable*/}
                {/*    swipeToSlide={true}*/}
                <h2 style={styleH2} className="styleH2">Основные направления деятельности</h2>
                {CarouselItem.map((item, index) => {
                        return (
                            <div>
                                <Row justify="space-around">
                                    <Col flex="1140px">
                                        <img style={style}
                                             key={index}
                                             src={item.img}
                                             alt={item.alt}
                                        />
                                        {/*<Parallax*/}
                                        {/*    animation={[*/}
                                        {/*        {x: 0, opacity: 1, playScale: [0, 0.3]},*/}
                                        {/*        {y: 0, playScale: [0, 0.4]},*/}
                                        {/*        {opacity: 0, playScale: [0, 1]},*/}
                                        {/*    ]}*/}
                                        {/*    style={{opacity: 0}}*/}
                                        {/*    className={item.parallax}*/}
                                        {/*>*/}
                                            <div style={{
                                                position: "absolute",
                                                top:"50%",
                                                left: '50%',
                                                backgroundColor: "white",
                                                display: "table",
                                                borderRadius: 50,
                                            }} className={item.classItem} key={item.key}>

                                                <div style={{
                                                    overflow: "auto",
                                                    display: "table-cell",
                                                    verticalAlign: "middle"
                                                }}>
                                                    <h4 style={styleH5} className="styleH4">{item.title}</h4>
                                                    <p style={styleP} className="description"
                                                       id="sliderP">{item.Description}</p>
                                                </div>
                                            </div>
                                        {/*</Parallax>*/}
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                )
                }
                {/*</Carousel>*/}
            </div>
        );
    }
}

export default Gallery