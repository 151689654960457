import TurkStream from "../../../../assets/Reviews/South Stream.jpg"
import AdmKomy from "../../../../assets/Reviews/AdmKomy.jpg"
import ITC from "../../../../assets/Reviews/ITC.jpg"
import GazpromRasp from "../../../../assets/Reviews/GazpromRasp.jpg"
import GazpromKyban from "../../../../assets/Reviews/GazpromKyban.jpg"
import GazpromMoskov from "../../../../assets/Reviews/GazpromMoskov.jpg"
import Oil from "../../../../assets/Reviews/Oil.jpg"
import PNGDiagnostics from "../../../../assets/Reviews/PNGDiagnostics.jpg"

export const ReviewsItem = [
    {
        img:TurkStream,
        alt:"Nord Stream",
    },
    {
        img:AdmKomy,
        alt:"Administration of the Komi-Permyatsky District",
    },
    {
        img:ITC,
        alt:"Engineering and technical center",
    },
    {
        img:GazpromRasp,
        alt:"Gazprom Distribution",
    },
    {
        img:GazpromKyban,
        alt:"Gazprom Kuban",
    },
    {
        img:GazpromMoskov,
        alt:"Gazprom Moscow",
    },    {
        img:Oil,
        alt:"Neftegazdetal",
    },
    {
        img:PNGDiagnostics,
        alt:"PNG Diagnostics",
    }
]