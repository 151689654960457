import React, {Component} from "react";
import {AboutUsItem} from "./AboutUsItem";
import {Row,Col} from "antd";
import BlockKeyInfo from "./blockKeyInfo/BlockKeyInfo";
import "./AboutUs.css"

const contentStyleH2 = {
    fontWeight:"bold",
    color: "#183323",
    textAlign: "center",
    alignContent: "center",
    margin:"80px 0px 30px 0px"
}

const contentStyleP = {
    lineHeight: 1.7,
    fontWeight: 400,
    alignContent: "center",
    color: "#183323",
    margin: 0
}

class AboutUs extends Component {


    render() {
        return (
            <div id="aboutUs">
                {AboutUsItem.map((item, index) => {
                        return (
                            <div key={index}>
                            <h2 className="styleH2" style={contentStyleH2}>{item.title}</h2>
                           <Row justify="space-around"><Col flex="1140px"><p  style={ contentStyleP } className="styleP">{item.content}</p>
                               <BlockKeyInfo/>
                           </Col></Row>
                            </div>
                        )
                    }
                )
                }

            </div>
        )
       }
}

export default AboutUs