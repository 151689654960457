import BannerAnim, { Element } from "rc-banner-anim";
import TweenOne from "rc-tween-one";
import "rc-banner-anim/assets/index.css";
import "./style.css";
import Energy from "../../../assets/mobileVersion/Energy/energy.jpeg";
import NewTechnologies from "../../../assets/mobileVersion/Technology/tech.png";
import Ecology from "../../../assets/mobileVersion/Ecology/Robot.jpeg";
const BgElement = Element.BgElement;

const Banner = () => {
  const main =  {
    minHeight: "100vh",
  }
  return (
    <BannerAnim prefixCls="banner-user" autoPlay style={main} type="across">
      <Element prefixCls="banner-user-elem" key="0">
        <BgElement
          key="bg"
          className="bg"
          style={{
            backgroundImage: `url(${Energy})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <TweenOne
          className="banner-user-title"
          animation={{ y: 30, opacity: 0, type: "from" }}
        >
          <strong style={{ textShadow: '10px 10px 10px black' }}>Энергия</strong>
        </TweenOne>
        <TweenOne
          className="banner-user-text"
          animation={{ y: 30, opacity: 0, type: "from", delay: 100 }}
        >
          <h5 style={{ color: "white", textShadow: '10px 10px 10px black' }}>
            Это ключевые слова, определяющие идеологию создания компании
          </h5>
        </TweenOne>
      </Element>

      <Element prefixCls="banner-user-elem" key="1">
        <BgElement
          key="bg"
          className="bg"
          style={{
            backgroundImage: `url(${NewTechnologies})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <TweenOne
          className="banner-user-title"
          animation={{ y: 30, opacity: 0, type: "from" }}
        >
          <strong style={{ color: "black", textShadow: '10px 10px 20px black' }}>Новые технологии</strong>
        </TweenOne>
        <TweenOne
          className="banner-user-text"
          animation={{ y: 30, opacity: 0, type: "from", delay: 100 }}
        >
          <h5 style={{ color: "black", textShadow: '10px 10px 20px black' }}>
            Это ключевые слова, определяющие идеологию создания компании
          </h5>
        </TweenOne>
      </Element>

      <Element prefixCls="banner-user-elem" key="2">
        <BgElement
          key="bg"
          className="bg"
          style={{
            backgroundImage: `url(${Ecology})`,
            backgroundSize: "cover",
            backgroundPosition: "60%",
          }}
        />
        <TweenOne
          className="banner-user-title"
          animation={{ y: 30, opacity: 0, type: "from" }}
        >
          <strong style={{ color: "white", textShadow: '10px 10px 20px black' }}>Экология</strong>
        </TweenOne>
        <TweenOne
          className="banner-user-text"
          animation={{ y: 30, opacity: 0, type: "from", delay: 100 }}
        >
          <h5 style={{ color: "white", textShadow: '10px 10px 20px black' }}>
            Это ключевые слова, определяющие идеологию создания компании
          </h5>
        </TweenOne>
      </Element>
    </BannerAnim>
  );
};

export default Banner;
