import React, { Component } from "react";
import { Row, Col, Space } from "antd";
// import Card from "./Card";

const contentStyleH2 = {
  fontWeight: "bold",
  color: "#183323",
  textAlign: "center",
  alignContent: "center",
  margin: "50px 0px 30px 0px",
};

class Presentation extends Component {
  render() {
    return (
      <div >
        <h2 style={contentStyleH2} className="styleH2">
          Presentation materials
        </h2>
        <Space size={3} direction="vertical">
          <Row justify="space-around">
            <Col flex="1140px">
              {/* <Card/> */}
              <video
                width="100%"
                loop
                controls={true}
                //   poster={dataSource.video.children.image}
              >
                <source
                  src="https://storapi.ente-ltd.ru/cdn/videos/eng_%D0%902072.mp4"
                  type={`video/mp4`}
                />
                <track kind="captions" />
              </video>
            </Col>
          </Row>
          <Row justify="space-around">
            <Col flex="1140px">
              {/* <Card/> */}
              <video
                width="100%"
                loop
                controls={true}
                //   poster={dataSource.video.children.image}
              >
                <source
                  src="https://storapi.ente-ltd.ru/cdn/videos/all_A2072.mp4"
                  type={`video/mp4`}
                />
                <track kind="captions" />
              </video>
            </Col>
          </Row>
        </Space>
      </div>
    );
  }
}

export default Presentation;
