export const MenuItems = [
    {
        title: "Энергия",
        url: '#energy',
        cName: 'nav-links'
    },
    {
        title: "Новые",
        url: '#new',
        cName: 'nav-links'
    },
    {
        title: "Технологии",
        url: '#technology',
        cName: 'nav-links'
    },
    {
        title: "Экология",
        url: '#ecology',
        cName: 'nav-links'
    },
    {
        title: "Портал",
        url: 'https://docs.ente-ltd.ru/',
        cName: 'nav-links'
    },

]