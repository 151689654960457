import License from "../../../assets/Doc/eco/license.jpg"
import Certificate from  "../../../assets/Doc/eco/certificateAboutAttestation.jpg"
import ISO from "../../../assets/Doc/eco/ISO.jpg"
import Reestr from "../../../assets/Doc/eco/reestr.jpg"
import LicensePDF from "../../../assets/Doc/eco/license.pdf"
import CertificatePDF from "../../../assets/Doc/eco/attestat.pdf"
import ISOPDF from "../../../assets/Doc/eco/ISO 9001.pdf"
import ReestrPDF from "../../../assets/Doc/eco/reestr.pdf"

export const CardItems = [
    {
        img:License,
        alt:"Лицензия",
        file: LicensePDF
    },
    {
        img:Certificate,
        alt:"Свидетельство",
        file: CertificatePDF
    },
    {
        img:ISO,
        alt:"ISO 9001:2015",
        file: ISOPDF
    },
    {
        img:Reestr,
        alt:"Реестр об аттестации",
        file: ReestrPDF
    },
]