import "./BlockKeyInfoItems.css"
import Robot from "../../../../assets/mobileVersion/Technology/Robot_2.jpeg"
import Emplyee from "../../../../assets/mobileVersion/Technology/Employee_3.jpeg"
import Chemodan from "../../../../assets/mobileVersion/Technology/Chemodan_2.jpeg"

const styleDescription = {
    color: "#183323",
    fontSize: '18px',
    margin:0
}

export const blockKeyInfoItems = [
    {
        // title: <h4 style={ styleTitle } className="styleH4">120+ сотрудников</h4>,
        icon: Robot,
        description: <p style={ styleDescription } className="description">Внутритрубное техническое диагностирование и обследование ТТ КС и ЛЧ МГ</p>,
    },
    {
        // title: <h4 style={ styleTitle } className="styleH4">20+ лет работы</h4>,
        icon: Emplyee,
        description: <p style={ styleDescription } className="description">Специализированные передвижные автолаборатории</p>,
    },
    {
        // title: <h4 style={ styleTitle } className="styleH4">30+ заказчиков</h4>,
        icon: Chemodan,
        description: <p style={ styleDescription } className="description">Диагностика технического состояния методами неразрушающего контроля
        </p>,
    },
]