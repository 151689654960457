import React, {Component} from "react";
import {CardItems} from "./CardItems";
import {Col, Image, Row} from "antd";
import "./CardHover.css"
import ModalPDF from "../../Buttons/ModalPDF/ModalPDF";

const style = {
    borderRadius: 15
}

const block = {
    backgroundColor: "white",
    borderRadius: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}

class Card extends Component {

    render() {
        return (
            <Row
                gutter={
                    [
                        30, 15
                    ]
                }>
                {CardItems.map((item, index) => {
                        return (
                            <Col xs={24} md={12} lg={6}>
                                <div style={block} className="CardDoc">
                                    <Image
                                        key={index}
                                        style={style}
                                        src={item.img}
                                        alt={item.alt}
                                    />
                                </div>
                                <div className="ModalPDF" id="card">
                                <ModalPDF title={item.alt} url={item.file} name={item.alt}/>
                                </div>
                            </Col>
                        )
                    }
                )
                }
            </Row>
        )
    }
}

export default Card

