import { ArrowUpOutlined } from "@ant-design/icons";
// import logo from "../../../../assets/logo-dark.svg";
import logo from "../../../../assets/logo.svg";
import { Button, Col, Row } from "antd";
import React, { Component } from "react";
import { MenuItems } from "../Header/Navbar/MenuItems";
import "./Footer.css";

const style = {
  marginTop: 80,
  backgroundColor: "#183323",
};

const prIzhevsk = {
  width: "100%",
  paddingLeft: "15px",
  margin: 0,
};

const prYugorsk = {
  paddingLeft: "15px",
  margin: 0,
};

const styleH4 = {
  color: "white",
  fontWeight: "bold",
  margin: 0,
  padding: "15px 0px 15px 0px",
};

const styleP = {
  margin: 0,
  color: "white",
};

const rowS = {
  color: "white",
  cursor: "pointer",
};

const navmenu = {
  margin: 0,
  display: "flex",
  flexDirection: "column",
  lineHeight: 2.2,
};

const LangBut = {
  border: "none",
  display: "none", //flex
  verticalAlign: "middle",
  marginLeft: "auto",
};

const contentStyleRu = {
  fontSize: 13,
  padding: "5px 10px",
  fontWeight: "bold",
  color: "white",
};

const contentStyleEn = {
  color: "white",
  fontSize: 12,
  padding: "5px 10px",
};

const contentStyle = {
  color: "white",
  textDecoration: "none",
  border: "none",
  background: "none",
};

class Footer extends Component {
  render() {
    return (
      <div style={style} id="contacs">
        <Row justify="space-around">
          <Col flex="1140px">
            <div className="FooterLogo">
              <img src={logo} alt="Logotype" />
              <p>
                <strong>LLC "ENTE"</strong>
                <br />
                Creating a secure future
              </p>

              <>
                <div style={LangBut}>
                  <Button style={contentStyleRu} className="RU" type="text">
                    RU
                  </Button>
                  <Button style={contentStyleEn} className="EN" type="text">
                    EN
                  </Button>
                </div>
              </>
            </div>
            <div className="blockInfo">
              <div className="HeadOffice">
                <h3 style={styleH4} className="styleH3">
                  Head office
                </h3>
                <p style={styleP} className="description">
                  <a href="https://yandex.ru/maps/-/CCUauLQNgC" target="_blank">
                    Postal/legal address:
                    <br />
                    617763, Perm region,
                    <br />
                    Chaikovsky,
                    <br />
                    Primorsky Boulevard, 32, 3rd floor
                    <br />
                    <br />
                  </a>
                  E-mail:
                  <br />
                  <a href="mailto:info@ente-ltd.ru">
                    {" "}
                    <strong>info@ente-ltd.ru</strong>{" "}
                  </a>{" "}
                  <br />
                  <br />
                  Phone/fax:
                  <br />
                  <strong>
                    <a href="tel:8 (34241) 3-46-80">8 (34241) 3-46-80</a>
                    <br />
                    <a href="tel:8 (34241) 3-78-98">8 (34241) 3-78-98</a>
                    <br />
                  </strong>
                  Secretary: (additional number: 1003)
                  <br />
                  <br />
                </p>
              </div>
              <div className="IzhYug">
                <div style={prIzhevsk}>
                  <br />
                  <p style={styleP} className="description">
                    General director:
                  </p>
                  <p style={styleP} className="description">
                    <strong>Lozhkin Egor Nikolaevich</strong>
                  </p>
                  <br />
                  <p style={styleP} className="description">
                    Executive director:
                  </p>
                  <p style={styleP} className="description">
                    <strong>Lebedeva Oksana Yurevna</strong>
                  </p>
                  <br />
                </div>
                <br />
                <div style={prYugorsk} className="Yugorsk">
                  <h3 style={styleH4} className="styleH3">
                    Separate structural <br /> subdivision of "ENTE-Yugra"{" "}
                  </h3>
                  <p style={styleP} className="description">
                    <a
                      href="https://yandex.ru/maps/-/CCUauPAnpB"
                      target="_blank"
                    >
                      628260, Khanty-Mansi Autonomous okrug, Yugorsk,
                      <br />
                      st. Promyshlennaya, 23
                    </a>
                  </p>
                  <br />
                  <h3 style={styleH4} className="styleH3">
                    Separate structural <br />
                    subdivision "ENTE-Izh"
                  </h3>
                  <p style={styleP} className="description">
                    <a
                      href="https://yandex.ru/maps/-/CCUauLdhHB"
                      target="_blank"
                    >
                      426008, Udmurt Republic, Izhevsk,
                      <br />
                      Severniy pereulok, 54
                    </a>
                  </p>
                </div>
              </div>
              <div style={navmenu} className="NavMenu">
                {MenuItems.map((item, index) => {
                  return (
                    <a
                      className={item.cName}
                      href={item.url}
                      style={{ color: "white" }}
                    >
                      {item.title}
                    </a>
                  );
                })}
              </div>
              <a href="#home" className="Arrow">
                <ArrowUpOutlined style={rowS} className="RowStyle" />
              </a>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Footer;
