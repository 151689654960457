import "./BlockKeyInfoItems.css"

const styleTitle = {
    color: "black",
    fontWeight:"bold",
    textTransform: "uppercase",
    margin: 0,
    fontSize: '20px'
}

const styleDescription = {
    color: "black",
    margin:0,
    fontSize: '20px'
}

export const blockKeyInfoItems = [
    {
        title: <h4 style={ styleTitle } className="styleH4">650+ заключений ЭПБ</h4>,
        // icon: Paper,
        description: <p style={ styleDescription } className="description">Экспертиза промышленной безопасности</p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">3000+ КМ Т/П</h4>,
        // icon: Pipes,
        description: <p style={ styleDescription } className="description">Диагностическое обследование</p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">1750+ ОБЪЕКТОВ</h4>,
        // icon: Something,
        description: <p style={ styleDescription } className="description">Внутритрубная диагностика</p>,
    },
]