import React from "react";
import { Card } from "antd";
import { blockKeyInfoItems } from "./BlockKeyInfoItems";
import { Row, Col } from "antd";

const { Meta } = Card;

const BlockKeyInfo = () => {
  return (
    <Row gutter={[7, 7]}>
      {blockKeyInfoItems.map((item, index) => {
        return (
          <Col xs={24} md={12} lg={6}>
            <Card
              key={index}
              style={{ backgroundColor: "rgba(255,255,255,0.0)", border: '0px' }}
            >
              <h4>{item.title}</h4>
              <div style={{ width: "100%", textAlign: "center" }}>
                <img src={item.icon} alt="img"/>
                <br />
              </div>
              <Meta
                description={item.description}
                style={{ textAlign: "center", wordWrap: "break-word" }}
              />
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};
export default BlockKeyInfo;
