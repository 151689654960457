import React, {Component} from "react";
import {Row,Col} from "antd";
import Card from "./Card";

const contentStyleH2 = {
    fontWeight:"bold",
    color: "#183323",
    textAlign: "center",
    alignContent: "center"
}


class Documentation extends Component {
    render() {
        return (
            <div className="Document">
                <h2 style={contentStyleH2} className="styleH2">Permits</h2>
                <Row justify="space-around"><Col flex="1140px">
                    <Card/>
                </Col></Row>
            </div>
        )
    }
}

export default Documentation