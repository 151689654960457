import React, { Component } from "react";
import context from "../../../context/context";
import { Button } from "antd";
import "./ButtonLang.css";

const activeLanguage = {
  fontSize: 13,
  padding: "5px 10px",
  border: "none",
  fontWeight: "bold",
  color: "#272590",
};

const language = {
  color: "#183323",
  fontSize: 12,
  padding: "5px 10px",
  border: "none",
};

class ButtonLang extends Component {
  static contextType = context;
  render() {
    return (
      <>
        <Button
          style={this.context.language === "ru" ? activeLanguage : language}
          className="RU"
          type="text"
          onClick={() => this.context.switchLanguage("ru")}
        >
          RU
        </Button>
        <Button
          style={this.context.language === "en" ? activeLanguage : language}
          className="EN"
          type="text"
          onClick={() => this.context.switchLanguage("en")}
        >
          EN
        </Button>
      </>
    );
  }
}

export default ButtonLang;
