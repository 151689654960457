import React, {Component} from "react";
import {Row,Col} from "antd";
import Card from "./ReviewsCard";

const contentStyleH3 = {
    fontWeight:"bold",
    color: "#183323",
    textAlign: "center",
    alignContent: "center"
}


class Documentation extends Component {
    render() {
        return (
            <div id="reviews">
                <h2 style={contentStyleH3} className="styleH2">Отзывы</h2>
                <Row justify="space-around"><Col flex="1140px">
                    <Card/>
                </Col></Row>
            </div>
        )
    }
}

export default Documentation