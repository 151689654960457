export const MenuItems = [
    {
        title: "Main",
        url: '#home',
        cName: 'nav-links'
    },
    {
        title: "About us",
        url: '#aboutUs',
        cName: 'nav-links'
    },
    {
        title: "Аctivities",
        url: '#activity',
        cName: 'nav-links'
    },
    {
        title: "Reviews",
        url: '#reviews',
        cName: 'nav-links'
    },
    {
        title: "Contacts",
        url: '#contacs',
        cName: 'nav-links'
    },
    {
        title: "Portal",
        url: 'https://docs.ente-ltd.ru/',
        cName: 'nav-links'
    },
    {
        title: "Documents",
        url: 'https://www.ente-ltd.ru/files/',
        cName: 'nav-links'
    }

]