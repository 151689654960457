import React, {Component} from "react";
import {ReviewsItem} from "./ReviewsItem";
import {Col, Row} from "antd";
import { Image } from 'antd';
import "./ReviewsHover.css"

const style = {
}

const block = {
    backgroundColor:"white",
    width:"100%",
    height:"100%",
    borderRadius:15,
    display: "flex",
    justifyContent:"center",
    alignItems:"center"
}

class Card extends Component {

    render() {
        return (
            <Row
                gutter={
                    [
                        30,30
                    ]
                }>
                {ReviewsItem.map((item, index) => {
                        return (
                            <Col xs={24} md={12} lg={6}>
                                <div style={block} className="reviews">
                                    <Image
                                        key={index}
                                        style={style}
                                        src={item.img}
                                        alt={item.alt}
                                    />
                                </div>
                            </Col>
                        )
                    }
                )
                }
            </Row>
        )
    }
}

export default Card