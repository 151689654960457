import React, {Component} from "react";
import {Row,Col} from "antd";
import Card from "./PartnersCard";

const contentStyleH3 = {
    fontWeight:"bold",
    color: "#183323",
    textAlign: "center",
    alignContent: "center",
    margin:"80px 0px 30px 0px"
}


class Partners extends Component {
    render() {
        return (
            <div id="partners">
                <h2 style={contentStyleH3} className="styleH2">Партнеры</h2>
                <Row justify="space-around"><Col flex="1140px">
                    <Card/>
                </Col></Row>
            </div>
        )
    }
}

export default Partners