import React from "react";
import "./BlockKeyInfoItems.css";
import { Card } from "antd";
import { blockKeyInfoItems } from "./BlockKeyInfoItems";

const BlockKeyInfo = () => {
  const description = {
    textAlign: "center",
  };
  return (
    <div className="main">
      <div className="container">
        {blockKeyInfoItems.map((item, index) => (
          <Card
            style={{ border: "none", backgroundColor: "rgba(0,0,0,0)" }}
            cover={<img alt="img" src={`${item.icon}`} />}
          >
            <Card.Meta
              description={<h5 style={description}>{item.description}</h5>}
            />
          </Card>
        ))}
      </div>
    </div>
  );
};
export default BlockKeyInfo;
