import {Typography} from "antd";

export const AboutUsItem = [
    {
        title: "О нас",
    content:
    <Typography.Text>
        Коллектив ООО «ЭНТЭ» - это более 150 высококвалифицированных специалистов (средний стаж работы по специальности - 10 лет), образующих сплоченную команду для решения задач любой сложности. Компания располагает собственной производственно-технической базой, современными комплексами неразрушающего контроля, передвижными специализированными диагностическими лабораториями, инновационными технологиями по направлениям деятельности.<br/><br/>
        Базовые принципы существования компании – высочайшее качество выполняемых работ, постоянное совершенствование методов и поиск инновационных решений поставленных задач, максимальная вовлеченность коллектива и раскрытие творческого потенциала сотрудников.<br/><br/>
        ООО «ЭНТЭ» активно сотрудничает и имеет партнерские отношения с Уфимским Государственным Нефтяным Техническим Университетом, Санкт-Петербургским Государственным Университетом, Ижевским Государственным Техническим Университетом, Институтом Прикладной Механики УрО РАН, Национальным Исследовательским Университетом «Московский Энергетический Институт», ООО «ИНТ «ГеоСпектр», ООО «АКС» и др.<br/><br/>
        Основные заказчики - ПАО «ГАЗПРОМ», ПАО «Газпром газораспределение», ПАО «ЛУКОЙЛ», АК «Транснефть» и др. География работ, выполняемых ООО «ЭНТЭ», простирается от Ямала до Краснодарского края, а также включает в себя зарубежные регионы.<br/><br/>
        Качество работы ООО «ЭНТЭ» подтверждают положительные отзывы от ОАО «Оргэнергогаз», ОАО «Газпром Газораспределение», ООО «Газпром Трансгаз Чайковский», ООО «Газпром Трансгаз Краснодар», ООО «Газпром Трансгаз Ставрополь», ООО «Газпром Трансгаз Москва» ООО «Газпром Трансгаз Казань», ООО «Газпром Трансгаз Сургут», ООО «Газпром Трансгаз Югорск», ООО «Газпром ПХГ», Администрации Пермского края, ООО «Нефтегаздеталь» и др.<br/><br/>
        ООО «ЭНТЭ» - социально ответственная компания, активно занимающаяся продвижением образовательных проектов и поддержкой детского спорта.<br/><br/>
        С участием ООО «ЭНТЭ» образованы компании АО «ИнтроСкан Технолоджи», ООО «Эликсир-Д».
    </Typography.Text>
    }

    ]
