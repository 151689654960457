import React from "react";
import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import "./ModalPDF.css"

const button = {
    borderRadius: 15,
    border:"none",
    backgroundColor: "#272590",
    color:"white",
    fontWeight:"bold"
}

class ModalPDF extends React.Component {
    state = {
        modal2Visible: false,
    };

    setModal2Visible(modal2Visible) {
        this.setState({modal2Visible});
    }

    render() {
        return (
            <>
            <Button block style={button} className="ModalPDF" type="primary" onClick={() => this.setModal2Visible(true)}>
                {this.props.title}
            </Button>
        <Modal
            title = {<p style={{ color:"#183323",textAlign:"center",fontSize:20,fontWeight:"bold",textTransform:"uppercase" }} > {this.props.name} </p>}
            footer={null}
            centered
            visible={this.state.modal2Visible}
            onCancel={() => this.setModal2Visible(false)}
        >
            <object data={this.props.url} type="application/pdf" width="490px" height="720px">
                <embed src={this.props.url} type="application/pdf">
                </embed>
            </object>
        </Modal>
            </>
        )
    }

}

export default ModalPDF