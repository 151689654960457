import React, { Component } from "react";
import "./CarouselBox.css";
import { CarouselItem } from "./CarouselItem";
import { Carousel } from "antd";
import Button from "../../Buttons/HomePage/Button";

export default class CarouselBox extends Component {
  ref = React.createRef();
  componentDidMount() {
    setTimeout(() => {
      try {
        this.ref.current.next();
      } catch {}
    }, 3000);
  }

  render() {
    return (
      <Carousel
        autoplay
        fade
        ref={this.ref}
        draggable={true}
        swipeToSlide={true}
        dots={false}
      >
        {CarouselItem.map((item, index) => {
          return (
            <div className="CarouselItem">
              <div
                className="content"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <h1>{item.title}</h1>
                <h3>{item.Description}</h3>
                <a href="#aboutUs">
                  <Button>{item.button}</Button>
                </a>
              </div>
              <img key={index} src={item.img} alt={item.alt} />
            </div>
          );
        })}
      </Carousel>
    );
  }
}
