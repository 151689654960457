import TurkStream from "../../../assets/Reviews/South Stream.jpg"
import AdmKomy from "../../../assets/Reviews/AdmKomy.jpg"
import ITC from "../../../assets/Reviews/ITC.jpg"
import GazpromRasp from "../../../assets/Reviews/GazpromRasp.jpg"
import GazpromKyban from "../../../assets/Reviews/GazpromKyban.jpg"
import GazpromMoskov from "../../../assets/Reviews/GazpromMoskov.jpg"
import Oil from "../../../assets/Reviews/Oil.jpg"
import PNGDiagnostics from "../../../assets/Reviews/PNGDiagnostics.jpg"

export const ReviewsItem = [
    {
        img:TurkStream,
        alt:"Северный поток",
    },
    {
        img:AdmKomy,
        alt:"Администрация Коми-Пермяцкого округа",
    },
    {
        img:ITC,
        alt:"Инженерно-технический центр",
    },
    {
        img:GazpromRasp,
        alt:"Газпром распределение",
    },
    {
        img:GazpromKyban,
        alt:"Газпром Кубань",
    },
    {
        img:GazpromMoskov,
        alt:"Газпром Москва",
    },    {
        img:Oil,
        alt:"Нефтегаздеталь",
    },
    {
        img:PNGDiagnostics,
        alt:"ПНГ-Диагностика",
    }
]