import "./BlockKeyInfoItems.css";
import Employees from "../../../../../assets/mobileVersion/Energy/Employees.svg";
import Foundation from "../../../../../assets/mobileVersion/Energy/Foundation.svg";
import Customers from "../../../../../assets/mobileVersion/Energy/Customers.svg";
import Introscan from "../../../../../assets/mobileVersion/Energy/Introscan.svg";
import World from "../../../../../assets/mobileVersion/Energy/World.svg";

const styleTitle = {
  color: "#183323",
  fontWeight: "bold",
  textTransform: "uppercase",
  margin: 0,
  fontSize: "20px",
};

const styleDescription = {
  color: "#183323",
  margin: 0,
  fontSize: "20px",
};

export const blockKeyInfoItems = [
  {
    title: (
      <h4 style={styleTitle} className="styleH4">
        150+ employees
      </h4>
    ),
    icon: Employees,
    description: (
      <p style={styleDescription} className="description">
        The staff of ENTE LLC is a close-knit team of highly qualified
        specialists from various industries
      </p>
    ),
  },
  {
    title: (
      <h4 style={styleTitle} className="styleH4">
        20+ years of work
      </h4>
    ),
    icon: Foundation,
    description: (
      <p style={styleDescription} className="description">
        Founded
        <br />
        August 1, 2000
      </p>
    ),
  },
  {
    title: (
      <h4 style={styleTitle} className="styleH4">
        2 branches
      </h4>
    ),
    icon: World,
    description: (
      <p style={styleDescription} className="description">
        ENTE-IZH (Izhevsk),
        <br />
        ENTE-YUGRA (Yugorsk)
      </p>
    ),
  },
  {
    title: (
      <h4 style={styleTitle} className="styleH4">
        Partners
      </h4>
    ),
    icon: Introscan,
    description: (
      <p style={styleDescription} className="description">
        JSC IntroScan Technology, LLC ACS,
        <br />
        JSC Control Systems,
        <br />
        Kalashnikov Izhevsk State Technical University,
        <br />
        Gazprom
      </p>
    ),
  },
  {
    title: (
      <h4 style={styleTitle} className="styleH4">
        30+ customers
      </h4>
    ),
    icon: Customers,
    description: (
      <p style={styleDescription} className="description">
        Russian and
        <br />
        foreign companies
      </p>
    ),
  },
];
