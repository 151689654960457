import {
TeamOutlined,
GlobalOutlined,
FundProjectionScreenOutlined,
LikeOutlined
} from '@ant-design/icons';
import "./BlockKeyInfoItems.css"

const style = {
    fontSize:100,
    borderRadius:16,
    color: "white",
    backgroundColor: "#389e0d",
    padding:"10px"
}

const styleTitle = {
    color: "#183323",
    fontWeight:"bold",
    textTransform: "uppercase",
    margin: 0
}

const styleDescription = {
    color: "#183323",
    margin:0
}

export const blockKeyInfoItems = [
    {
        title: <h4 style={ styleTitle } className="styleH4">20+ лет работы</h4>,
        icon: <FundProjectionScreenOutlined style={style}/>,
        description: <p style={ styleDescription } className="description">Дата основания <br/> 1 августа 2000</p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">150+ сотрудников</h4>,
        icon: <TeamOutlined style={style}/>,
        description: <p style={ styleDescription } className="description">Малое инновационное предприятие</p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">30+ заказчиков</h4>,
        icon: <LikeOutlined style={style}/>,
        description: <p style={ styleDescription } className="description">В том числе 19 дочерних обществ ПАО «Газпром»</p>,
    },
    {
        title: <h4 style={ styleTitle } className="styleH4">40+ регионов</h4>,
        icon: <GlobalOutlined style={style}/>,
        description: <p style={ styleDescription } className="description">Россия и ближнее <br/> зарубежье</p>,
    },
]