export const MenuItems = [
    {
        title: "Главная",
        url: '#home',
        cName: 'nav-links'
    },
    {
        title: "О нас",
        url: '#aboutUs',
        cName: 'nav-links'
    },
    {
        title: "Деятельность",
        url: '#activity',
        cName: 'nav-links'
    },
    {
        title: "Отзывы",
        url: '#reviews',
        cName: 'nav-links'
    },
    {
        title: "Контакты",
        url: '#contacs',
        cName: 'nav-links'
    },
    {
        title: "Портал",
        url: 'https://docs.ente-ltd.ru/',
        cName: 'nav-links'
    },
    {
        title: "Документы",
        url: 'https://www.ente-ltd.ru/files/',
        cName: 'nav-links'
    }

]